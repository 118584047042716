import * as React from 'react'
import Helmet from 'react-helmet'

export default () =>
  <main className="edrig-fourofour">
    <Helmet>
      <title>Page Not Found | Edouard Rigaudière - Film Composer</title>
      <meta property="og:title" content="Page Not Found | Edouard Rigaudière - Film Composer" />
      <meta name="twitter:title" content="Page Not Found | Edouard Rigaudière - Film Composer" />
    </Helmet>
    <h1 className="edrig-fourofour__title">404</h1>
  </main>
